/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

type OtherLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'target' | 'className' | 'children' | 'onClick' | 'data-testid' | 'underline'>
type UseLinkProps = {
  href?: React.AnchorHTMLAttributes<HTMLAnchorElement>['href']
  target?: React.AnchorHTMLAttributes<HTMLAnchorElement>['target']
  className?: React.AnchorHTMLAttributes<HTMLAnchorElement>['className']
  children?: React.AnchorHTMLAttributes<HTMLAnchorElement>['children']
  onClick?: React.AnchorHTMLAttributes<HTMLAnchorElement>['onClick']
}

export interface LinkProps extends OtherLinkProps, UseLinkProps {
  rightSideIcon?: boolean,
  iconColor?: string,
  'data-testid'?: string,
  underline?: boolean
  RenderableTag?: React.FC<UseLinkProps>
  enableIframeNavigation?: boolean
}

const DefaultRenderableTag: React.FC<UseLinkProps> = ({
  href,
  target = '_self',
  className = '',
  children,
  onClick,
  ...rest
}) => (
  <a
    className={className}
    href={href}
    target={target}
    onClick={onClick}
    {...rest}
  >
    {children}
  </a>
)

export const Link: React.FC<LinkProps> = ({
  href,
  target = '_self',
  underline = true,
  onClick,
  className = '',
  children,
  RenderableTag = DefaultRenderableTag,
  enableIframeNavigation = true,
  ...rest
}) => (
  <RenderableTag
    className={`${className || ''} ${underline ? 'underline' : 'no-underline'} cursor-pointer hover:text-accent-2`}
    href={href}
    target={target}
    onClick={(e) => {
      onClick?.(e)
      if (enableIframeNavigation && target === '_self') { // TODO: define a better way to avoid iframe target _self restriction
        try {
          e?.preventDefault?.()
          e?.stopPropagation?.()
          if (
            !!window.top
            && window.self !== window.top
            && !!href) { // Inside Iframe
            window.top.location.href = href
          } else if (href) {
            window.location.href = href
          }
        } catch { //  Fail to access window may be due to frame constrains
          try {
            e?.preventDefault?.()
            window.open(href)
          } catch (error) {
            console.error(`Failed to trigger iframe link target _self\n${String(error)}`)
          }
        }
      }
    }}
    {...rest}
  >
    {children}
  </RenderableTag>
)

export default Link
